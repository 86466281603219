* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.container {
  box-shadow: 0px 0px 12px #ccc;
  border-radius: 8px;
  padding: 3rem;
  margin: 5% 30% 5% 30%;
}

@media only screen and (max-width: 600px) {
  .container {
    margin: 5% 10%;
  }
}

input {
  width: 100%;
  font-size: 1.2rem;
  padding: 15px 4px;
  margin: 8px 0;
  border-radius: 8px;
}

.btn {
  display: block;
  width: 100%;
  font-size: 1.2rem;
  margin: 8px 0;
  padding: 15px 0;
  background-color: #0077ee;
  color: #fff;
  border: 1px solid #333;
  border-radius: 8px;
  cursor: pointer;
}
/* unvisited link */

.btn-outline {
  background-color: #fff;
  color: #a6bcda;
}

.center {
  text-align: center;
  margin: 24px 0;
}

p {
  margin: 10px 0;
}

.img-container {
  text-align: center;
}

.img-container img {
  height: 200px;
}
